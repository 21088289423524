import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
 **/
export const constantRouterMap = [
  { path: '/login', component: () => import('@/views/login/index'), hidden: true },
  { path: '/404', component: () => import('@/views/404'), hidden: true },
  {
    path: '',
    component: Layout,
    redirect: '/home',
    children: [{
      path: 'home',
      name: 'home',
      component: () => import('@/views/home/index'),
      meta: { title: '首页', icon: 'home' },
    },{
      path: 'personalMessage',
      name: 'personalMessage',
      component: () => import('@/views/personalMessage'),
      meta: { title: '个人信息', icon: 'personalMessage' },
      hidden: true
    }]
  }
];

export const asyncRouterMap = [
  /*
  *
  * 会员
  *
  * */
  {
    path: '/user',
    component: Layout,
    redirect: '/user/member',
    name: 'user',
    meta: { title: '会员中心', icon: 'user' },
    children: [
      {
        path: 'member',
        name: 'member',
        component: () => import('@/views/user/member/index'),
        meta: { title: '会员列表', icon: 'huiyuanliebiao' }
      },
      {
        path: 'addMember',
        name: 'addMember',
        component: () => import('@/views/user/member/add'),
        meta: { title: '添加会员' },
        hidden: true
      },
      {
        path: 'editMember',
        name: 'editMember',
        component: () => import('@/views/user/member/update'),
        meta: { title: '编辑会员' },
        hidden: true
      },
      {
        path: 'doctor',
        name: 'doctor',
        component: () => import('@/views/user/doctor/index'),
        meta: { title: '(医师)列表', icon: 'yisheng' }
      },
      {
        path: 'updateDoctor',
        name: 'updateDoctor',
        component: () => import('@/views/user/doctor/update'),
        meta: { title: '更新医师' },
        hidden: true
      },
      {
        path: 'addDoctor',
        name: 'addDoctor',
        component: () => import('@/views/user/doctor/add'),
        meta: { title: '添加医师' },
        hidden: true
      },
      {
        path: 'administrator',
        name: 'administrator',
        component: () => import('@/views/user/administrator/index'),
        meta: { title: '(健康管理师)列表', icon: 'jiankangguanli' }
      },
      {
        path: 'updateAdministrator',
        name: 'updateAdministrator',
        component: () => import('@/views/user/administrator/update'),
        meta: { title: '更新健康管理师' },
        hidden: true
      },
      {
        path: 'addAdministrator',
        name: 'addAdministrator',
        component: () => import('@/views/user/administrator/add'),
        meta: { title: '添加健康管理师' },
        hidden: true
      },
      {
        path: 'assistant',
        name: 'assistant',
        component: () => import('@/views/user/assistant/index'),
        meta: { title: '(健康管家)列表', icon: 'xiaozhushouhdpi' }
      },
      {
        path: 'updateAssistant',
        name: 'updateAssistant',
        component: () => import('@/views/user/assistant/update'),
        meta: { title: '更新健康管家' },
        hidden: true
      },
      {
        path: 'addAssistant',
        name: 'addAssistant',
        component: () => import('@/views/user/assistant/add'),
        meta: { title: '添加健康管家' },
        hidden: true
      },
      {
        path: 'MembershipLevel',
        name: 'MembershipLevel',
        component: () => import('@/views/user/MembershipLevel/index'),
        meta: { title: '会员等级管理', icon: 'huiyuandengji0101' }
      },
      {
        path: 'updateMembershipLevel',
        name: 'updateMembershipLevel',
        component: () => import('@/views/user/MembershipLevel/update'),
        meta: { title: '添加会员等级' },
        hidden: true
      },
      {
        path: 'addMembershipLevel',
        name: 'addMembershipLevel',
        component: () => import('@/views/user/MembershipLevel/add'),
        meta: { title: '编辑会员等级' },
        hidden: true
      },
      {
        path: 'FamilyAccount',
        name: 'FamilyAccount',
        component: () => import('@/views/user/FamilyAccount/index'),
        meta: { title: '家庭账户管理', icon: 'jiating' }
      },
      {
        path: 'updateFamilyAccount',
        name: 'updateFamilyAccount',
        component: () => import('@/views/user/FamilyAccount/update'),
        meta: { title: '编辑家庭账户' },
        hidden: true
      },
      {
        path: 'addFamilyAccount',
        name: 'addFamilyAccount',
        component: () => import('@/views/user/FamilyAccount/add'),
        meta: { title: '添加家庭账户' },
        hidden: true
      },
      {
        path: 'doctorEvaluate',
        name: 'doctorEvaluate',
        component: () => import('@/views/user/doctorEvaluate'),
        meta: { title: '医师评价' },
      }
    ]
  },

  /*
  *
  * 服务
  *
  * */
  {
    path: '/server',
    component: Layout,
    redirect: '/server/serverAvtive',
    name: 'server',
    meta: { title: '服务资源管理', icon: 'fuwu' },
    children: [
      {
        path: 'serverAvtive',
        name: 'serverAvtive',
        component: () => import('@/views/server/serverActive/index'),
        meta: { title: '服务列表', icon: 'fuwuguanli' }
      },
      {
        path: 'addServerActive',
        name: 'addServerActive',
        component: () => import('@/views/server/serverActive/add'),
        meta: { title: '添加服务' },
        hidden: true
      },
      {
        path: 'editServerActive',
        name: 'editServerActive',
        component: () => import('@/views/server/serverActive/update'),
        meta: { title: '编辑服务' },
        hidden: true
      },
      {
        path: 'consultingService',
        name: 'consultingService',
        component: () => import('@/views/server/consultingService/index'),
        meta: { title: '咨询列表', icon: 'wangshangzixunguanli' }
      },
      {
        path: 'addConsultingService',
        name: 'addConsultingService',
        component: () => import('@/views/server/consultingService/add'),
        meta: { title: '添加咨询' },
        hidden: true
      },
      {
        path: 'editConsultingService',
        name: 'editConsultingService',
        component: () => import('@/views/server/consultingService/update'),
        meta: { title: '编辑咨询' },
        hidden: true
      },
      {
        path: 'servereEvaluate',
        name: 'servereEvaluate',
        component: () => import('@/views/server/servereEvaluate'),
        meta: { title: '评价管理' },
      }
    ]
  },

  /*
  *
  * 商品
  *
  * */
  {
    path: '/pms',
    component: Layout,
    redirect: '/pms/product',
    name: 'pms',
    meta: { title: '商品', icon: 'product' },
    children: [
      {
        path: 'product',
        name: 'product',
        component: () => import('@/views/pms/product/index'),
        meta: { title: '商品列表', icon: 'product-list' }
      },
      {
        path: 'addProduct',
        name: 'addProduct',
        component: () => import('@/views/pms/product/add'),
        meta: { title: '添加商品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'updateProduct',
        name: 'updateProduct',
        component: () => import('@/views/pms/product/update'),
        meta: { title: '修改商品', icon: 'product-add' },
        hidden: true
      },
      {
        path: 'shoppingEvaluate',
        name: 'shoppingEvaluate',
        component: () => import('@/views/pms/shoppingEvaluate/index'),
        meta: { title: '评价管理', icon: 'product-list' }
      },
      {
        path: 'productCate',
        name: 'productCate',
        component: () => import('@/views/pms/productCate/index'),
        meta: { title: '商品分类', icon: 'product-cate' }
      },
      {
        path: 'addProductCate',
        name: 'addProductCate',
        component: () => import('@/views/pms/productCate/add'),
        meta: { title: '添加商品分类' },
        hidden: true
      },
      {
        path: 'updateProductCate',
        name: 'updateProductCate',
        component: () => import('@/views/pms/productCate/update'),
        meta: { title: '修改商品分类' },
        hidden: true
      },
      {
        path: 'productAttr',
        name: 'productAttr',
        component: () => import('@/views/pms/productAttr/index'),
        meta: { title: '商品类型', icon: 'product-attr' }
      },
      {
        path: 'productAttrList',
        name: 'productAttrList',
        component: () => import('@/views/pms/productAttr/productAttrList'),
        meta: { title: '商品属性列表' },
        hidden: true
      },
      {
        path: 'addProductAttr',
        name: 'addProductAttr',
        component: () => import('@/views/pms/productAttr/addProductAttr'),
        meta: { title: '添加商品属性' },
        hidden: true
      },
      {
        path: 'updateProductAttr',
        name: 'updateProductAttr',
        component: () => import('@/views/pms/productAttr/updateProductAttr'),
        meta: { title: '修改商品属性' },
        hidden: true
      },
      {
        path: 'brand',
        name: 'brand',
        component: () => import('@/views/pms/brand/index'),
        meta: { title: '品牌管理', icon: 'product-brand' }
      },
      {
        path: 'addBrand',
        name: 'addBrand',
        component: () => import('@/views/pms/brand/add'),
        meta: { title: '添加品牌' },
        hidden: true
      },
      {
        path: 'updateBrand',
        name: 'updateBrand',
        component: () => import('@/views/pms/brand/update'),
        meta: { title: '编辑品牌' },
        hidden: true
      }
    ]
  },

  /*
  *
  * 订单
  *
  * */
  {
    path: '/oms',
    component: Layout,
    redirect: '/oms/order',
    name: 'oms',
    meta: { title: '订单', icon: 'order' },
    children: [
      {
        path: 'order',
        name: 'order',
        component: () => import('@/views/oms/order/index'),
        meta: { title: '订单列表', icon: 'product-list' }
      },
      {
        path: 'orderDetail',
        name: 'orderDetail',
        component: () => import('@/views/oms/order/orderDetail'),
        meta: { title: '订单详情' },
        hidden: true
      },
      {
        path: 'deliverOrderList',
        name: 'deliverOrderList',
        component: () => import('@/views/oms/order/deliverOrderList'),
        meta: { title: '发货列表' },
        hidden: true
      },
      {
        path: 'orderSetting',
        name: 'orderSetting',
        component: () => import('@/views/oms/order/setting'),
        meta: { title: '订单设置', icon: 'order-setting' }
      },
      {
        path: 'settingAddress',
        name: 'settingAddress',
        component: () => import('@/views/oms/order/settingAddress'),
        meta: { title: '退货地址', icon: 'order-setting' }
      },
      {
        path: 'returnApply',
        name: 'returnApply',
        component: () => import('@/views/oms/apply/index'),
        meta: { title: '退货申请处理', icon: 'order-return' }
      },
      {
        path: 'returnReason',
        name: 'returnReason',
        component: () => import('@/views/oms/apply/reason'),
        meta: { title: '退货原因设置', icon: 'order-return-reason' }
      },
      {
        path: 'returnApplyDetail',
        name: 'returnApplyDetail',
        component: () => import('@/views/oms/apply/applyDetail'),
        meta: { title: '退货原因详情' },
        hidden: true
      }
    ]
  },


  /*
  *
  * 营销
  *
  * */
  {
    path: '/sms',
    component: Layout,
    redirect: '/sms/coupon',
    name: 'sms',
    meta: { title: '营销', icon: 'sms' },
    children: [
      {
        path: 'flash',
        name: 'flash',
        component: () => import('@/views/sms/flash/index'),
        meta: { title: '秒杀活动列表', icon: 'sms-flash' }
      },
      {
        path: 'flashSession',
        name: 'flashSession',
        component: () => import('@/views/sms/flash/sessionList'),
        meta: { title: '秒杀时间段列表' },
        hidden: true
      },
      {
        path: 'selectSession',
        name: 'selectSession',
        component: () => import('@/views/sms/flash/selectSessionList'),
        meta: { title: '秒杀时间段选择' },
        hidden: true
      },
      {
        path: 'flashProductRelation',
        name: 'flashProductRelation',
        component: () => import('@/views/sms/flash/productRelationList'),
        meta: { title: '秒杀商品列表' },
        hidden: true
      },
      {
        path: 'coupon',
        name: 'coupon',
        component: () => import('@/views/sms/coupon/index'),
        meta: { title: '优惠券列表', icon: 'sms-coupon' }
      },
      {
        path: 'addCoupon',
        name: 'addCoupon',
        component: () => import('@/views/sms/coupon/add'),
        meta: { title: '添加优惠券' },
        hidden: true
      },
      {
        path: 'updateCoupon',
        name: 'updateCoupon',
        component: () => import('@/views/sms/coupon/update'),
        meta: { title: '修改优惠券' },
        hidden: true
      },
      {
        path: 'couponHistory',
        name: 'couponHistory',
        component: () => import('@/views/sms/coupon/history'),
        meta: { title: '优惠券领取详情' },
        hidden: true
      },
      {
        path: 'brand',
        name: 'homeBrand',
        component: () => import('@/views/sms/brand/index'),
        meta: { title: '品牌推荐', icon: 'product-brand' }
      },
      {
        path: 'new',
        name: 'homeNew',
        component: () => import('@/views/sms/new/index'),
        meta: { title: '新品推荐', icon: 'sms-new' }
      },
      {
        path: 'hot',
        name: 'homeHot',
        component: () => import('@/views/sms/hot/index'),
        meta: { title: '人气推荐', icon: 'sms-hot' }
      },

      {
        path: 'subject',
        name: 'homeSubject',
        component: () => import('@/views/sms/subject/index'),
        meta: { title: '专题列表', icon: 'sms-subject' }
      },
      {
        path: 'add',
        name: 'add',
        component: () => import('@/views/sms/subject/add'),
        meta: { title: '新增专题', icon: 'sms-subject' },
        hidden: true
      },
      {
        path: 'edit',
        name: 'edit',
        component: () => import('@/views/sms/subject/update'),
        meta: { title: '编辑专题', icon: 'sms-subject' },
        hidden: true
      },

      {
        path: 'advertise',
        name: 'homeAdvertise',
        component: () => import('@/views/sms/advertise/index'),
        meta: { title: '广告列表', icon: 'sms-ad' }
      },
      {
        path: 'addAdvertise',
        name: 'addHomeAdvertise',
        component: () => import('@/views/sms/advertise/add'),
        meta: { title: '添加广告' },
        hidden: true
      },
      {
        path: 'updateAdvertise',
        name: 'updateHomeAdvertise',
        component: () => import('@/views/sms/advertise/update'),
        meta: { title: '编辑广告' },
        hidden: true
      }
    ]
  },


  /*
*
* 积分
*
* */
  {
    path: '/integral',
    component: Layout,
    redirect: '/integral/memberIntegral',
    name: 'integral',
    meta: { title: '积分', icon: 'tubiao309' },
    children: [
      {
        path: 'memberIntegral',
        name: 'memberIntegral',
        component: () => import('@/views/integral/memberIntegral/index'),
        meta: { title: '会员积分', icon: 'jifenpeizhi' }
      },
      {
        path: 'familyIntegral',
        name: 'familyIntegral',
        component: () => import('@/views/integral/familyIntegral/index'),
        meta: { title: '家庭积分', icon: 'jifenguanli' }
      },
       {
         path: 'integralRecord',
         name: 'integralRecord',
         component: () => import('@/views/integral/integralRecord/index'),
         meta: { title: '变动记录', icon: 'jilu' }
       }
    ]
  },


  /*
  *
  *运动
  *
  */
 {
   path: '/act',
   component: Layout,
   redirect: '/act/actlist',
   name: 'act',
   meta: { title: '健康', icon: 'jiankangma' },
   children: [
     {
       path: 'actlist',
       name: 'actlist',
       component: () => import('@/views/act/actlist/index'),
       meta: { title: '打卡活动', icon: 'huodong1' }
     },
     {
      path: 'addAct',
      name: 'addAct',
      component: () => import('@/views/act/actlist/add'),
      meta: { title: '添加打卡活动', icon: 'product-add' },
      hidden: true
     },
     {
      path: 'editAct',
      name: 'editAct',
      component: () => import('@/views/act/actlist/update'),
      meta: { title: '编辑打卡活动', icon: 'product-add' },
      hidden: true
     },
     {
       path: 'bandList',
       name: 'bandList',
       component: () => import('@/views/act/bandList/index'),
       meta: { title: '绑定列表', icon: 'jilu' }
     },
     {
       path: 'addActive',
       name: 'addActive',
       component: () => import('@/views/act/bandList/addOrEdit'),
       meta: { title: '添加/编辑绑定', icon: 'product-add' },
       hidden: true
     }
  ]
},

  /*
  *
  * 反馈
  *
  * */
  {
    path: '/Feedback',
    component: Layout,
    redirect: '/Feedback/FeedbackLabel',
    name: 'Feedback',
    meta: { title: '反馈', icon: 'xinicon_huabanfuben' },
    children: [
      {
        path: 'FeedbackList',
        name: 'FeedbackList',
        component: () => import('@/views/Feedback/FeedbackList/index'),
        meta: { title: '反馈列表', icon: 'fankuiliebiao' }
      },
      {
        path: 'FeedbackLabel',
        name: 'FeedbackLabel',
        component: () => import('@/views/Feedback/FeedbackLabel/index'),
        meta: { title: '反馈标签', icon: 'biaoqian' }
      }
    ]
  },

  /*
  *
  * 权限
  *
  * */
  {
    path: '/ums',
    component: Layout,
    redirect: '/ums/admin',
    name: 'ums',
    meta: { title: '权限', icon: 'ums' },
    children: [
      {
        path: 'admin',
        name: 'admin',
        component: () => import('@/views/ums/admin/index'),
        meta: { title: '用户列表', icon: 'ums-admin' }
      },
      {
        path: 'role',
        name: 'role',
        component: () => import('@/views/ums/role/index'),
        meta: { title: '角色列表', icon: 'ums-role' }
      },
      {
        path: 'allocMenu',
        name: 'allocMenu',
        component: () => import('@/views/ums/role/allocMenu'),
        meta: { title: '分配菜单' },
        hidden: true
      },
      {
        path: 'allocResource',
        name: 'allocResource',
        component: () => import('@/views/ums/role/allocResource'),
        meta: { title: '分配资源' },
        hidden: true
      },
      {
        path: 'menu',
        name: 'menu',
        component: () => import('@/views/ums/menu/index'),
        meta: { title: '菜单列表', icon: 'ums-menu' }
      },
      {
        path: 'appVersion',
        name: 'appVersion',
        component: () => import('@/views/ums/appVersion/index'),
        meta: { title: '版本控制', icon: 'appVersion' }
      },
      {
        path: 'addVersion',
        name: 'addVersion',
        component: () => import('@/views/ums/appVersion/add'),
        meta: { title: '新增版本', icon: 'addAppVersion' },
        hidden: true
      },
      {
        path: 'editVersion',
        name: 'editVersion',
        component: () => import('@/views/ums/appVersion/update'),
        meta: { title: '编辑版本', icon: 'editVersion' },
        hidden: true
      },
      {
        path: 'addMenu',
        name: 'addMenu',
        component: () => import('@/views/ums/menu/add'),
        meta: { title: '添加菜单' },
        hidden: true
      },
      {
        path: 'updateMenu',
        name: 'updateMenu',
        component: () => import('@/views/ums/menu/update'),
        meta: { title: '修改菜单' },
        hidden: true
      },
      {
        path: 'resource',
        name: 'resource',
        component: () => import('@/views/ums/resource/index'),
        meta: { title: '资源列表', icon: 'ums-resource' }
      },
      {
        path: 'resourceCategory',
        name: 'resourceCategory',
        component: () => import('@/views/ums/resource/categoryList'),
        meta: { title: '资源分类' },
        hidden: true
      },
      {
        path: 'dataDictionary',
        name: 'dataDictionary',
        component: () => import('@/views/ums/dataDictionary/index'),
        meta: { title: '数据字典' },
        hidden: true
      },
      {
        path: 'dictionChild',
        name: 'dictionChild',
        component: () => import('@/views/ums/dataDictionary/dictionChild'),
        meta: { title: '字典配置' },
        hidden: true
      }
    ]
  },

  { path: '*', redirect: '/404', hidden: true }
]

export default new Router({
  // mode: 'history', //后端支持可开
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    { path: '/login', component: () => import('@/views/login/index'), hidden: true },
    { path: '/404', component: () => import('@/views/404'), hidden: true },
    {
      path: '',
      component: Layout,
      redirect: '/home',
      children: [{
        path: 'home',
        name: 'home',
        component: () => import('@/views/home/index'),
        meta: { title: '首页', icon: 'home' },
      },{
        path: 'personalMessage',
        name: 'personalMessage',
        component: () => import('@/views/personalMessage'),
        meta: { title: '个人信息', icon: 'personalMessage' },
        hidden: true
      }]
    }
  ]
})

